// https://logaretm.github.io/vee-validate/guide/rules.html
import { required, confirmed, length, email, alpha, alpha_num, digits, numeric, min, max, max_value } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'

extend('required', {
	...required,
	message: 'Este campo es obligatorio'
})

extend('email', {
	...email,
	message: 'Introduzca un correo electrónico válido'
})

extend('alpha', {
	...alpha,
	message: 'Escribe solo letras'
})

extend('alpha_num', {
	...alpha_num,
	message: 'Ingrese solo números o caracteres especiales'
})

extend('confirmed', {
	...confirmed,
	message: 'El campo de confirmación es diferente.'
})

extend('length', {
	...length,
	message: 'Seleccione al menos {length} opciones'
})

extend('min', {
	...min,
	message: 'Introduzca al menos {length} caracteres'
})

extend('max', {
	...max,
	message: 'Introduzca un máximo de {length} caracteres'
})

extend('digits', {
	...digits,
	message: 'Introduzca sólo números'
})

extend('numeric', {
	...numeric,
	message: 'Introduzca sólo números'
})

extend('max_value', {
	...max_value,
	message: 'Su nombre debe tener {length} caracteres'
})
